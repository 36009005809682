import {Route} from '@angular/router';
import {InitialDataResolver} from 'app/app.resolvers';
import {LayoutComponent} from './layout/layout.component';

export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch: 'full', redirectTo: '/projects'},

    // Auth routes (guest)
    {
        path: 'auth',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: '',
                redirectTo: 'sign-in',
                pathMatch: 'full'
            },
            {
                path: 'verify/email/:hash',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'password/recovery/:hash',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },

    // Admin routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        data: {
            layout: 'futuristic'
        },
        children: [
            {
                path: '',
                loadChildren: () => import('app/modules/main/main.module').then(m => m.MainModule)
            }
        ]
    },

    // 404 & Catch all
    {
        path: '404-not-found',
        pathMatch: 'full',
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('app/modules/errors/errors.module').then(m => m.ErrorsModule)
    },
    {path: '**', redirectTo: '404-not-found'},
];
