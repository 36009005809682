/** Angular */
import {ChangeDetectorRef, Injectable} from '@angular/core';

import {AbstractControl, FormGroup, Validators} from '@angular/forms';

import numeral from 'numeral';
import moment from 'moment';
import {isObject} from 'rxjs/internal-compatibility';
import {isBoolean} from 'lodash-es';

@Injectable()
export class FormUtilsService {

    constructor() {
    }

    getErrorMessage(form: any, controlName: string, minLength: number = 3, maxlength: number = 320, minValue: number = 1, maxValue: number = 3): any {
        const control = form.controls[controlName];
        if (control.hasError('minlength')) {
            return 'Minimum field length: ' + minLength;
        }
        if (control.hasError('pattern') && controlName === 'url') {
            return 'You entered an incorrect link';
        }
        if (control.hasError('pattern')) {
            return 'You entered an incorrect pattern';
        }
        if (control.hasError('maxlength')) {
            return 'Maximum field length: ' + maxlength;
        }
        if (control.hasError('matDatetimePickerMin')) {
            return 'Stop at must be after Start at';
        }
        if (control.hasError('min')) {
            return 'The value must be greater than ' + control.errors.min.min;
        }
        if (control.hasError('max')) {
            return 'The value may not be greater than ' + control.errors.max.max;
        }
        if (control.hasError('email')) {
            return 'Not a valid email';
        }
        if (control.hasError('validatePhoneNumber')) {
            return 'Not a valid phone number';
        }
        if (control.hasError('mustMatch')) {
            return 'New password and confirm new password do not match';
        }
        if (control.hasError('serverError')) {
            return control.getError('serverError');
        }
        return control.hasError('required') ? 'You must enter a value' : '';
    }

    prepareServerError(response: any = null, form: FormGroup = null, cdr: ChangeDetectorRef = null): any {
        if (!response || !response.error) {
            return console.log('!response.error');
        }
        let errors = response.error.errors;
        if (!errors && response.error.error && typeof response.error.error !== 'string') {
            errors = response.error.error;
        }
        if (errors) {
            Object.keys(errors).map(field => {
                let error = '';
                if (errors[field] && errors[field].length) {
                    errors[field].map(text => {
                        error += text;
                    });
                }
                if (form && form.controls[field]) {
                    form.controls[field].setErrors({'serverError': error});
                }
            });
            // Mark for check
            if (cdr) {
                cdr.markForCheck();
            }
            const message = response.error.message ? response.error.message : 'The given data was invalid.';
            return this.getMessage(message);
        } else {
            // Show the error message
            let message = 'The given data was invalid.';
            if (response.error.error && typeof response.error.error === 'string') {
                message = response.error.error;
            } else if (response.error && response.error === 'string') {
                message = response.error;
            }
            return this.getMessage(message);
        }
    }

    getMessage(message: string, type: string = 'error'): any {
        return {
            appearance: 'outline',
            content: message,
            shake: true,
            showIcon: false,
            type: type
        };
    }

    selectFilter(selectArray: any, value: string): string[] {
        const filterValue = this.normalizeValue(value);
        return selectArray.filter(item => {
            const itemValue = item.name ? item.name : item.label ? item.label : item;
            return this.normalizeValue(itemValue).includes(filterValue);
        });
    }

    normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    checkRequired(form, field): boolean {
        if (form && form.get(field) && form.get(field).validator) {
            return form.get(field).validator({} as AbstractControl) && form.get(field).validator({} as AbstractControl).required;
        }
        return false;
    }

    cleanIncludeExclude(value: string, isValidEmail: boolean = false): string {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        value = this.delBlankLines(value);
        value = value.replace(/[,]{2,}/g, ',').replace(/%/g, '')
            .replace(/\r\n|\n|\r/g, ',').replace(/(^[ \t]*\n)/gm, '');
        let tempArr = value.split(',');
        if (tempArr && tempArr.length) {
            tempArr = tempArr.map(val => {
                return val.trim().replace(/\s{2,}/g, ' ').replace(/\.$/, '');
            });
            if (isValidEmail) {
                tempArr = tempArr.filter(val => {
                    return emailReg.test(val);
                });
            }
            // удаление пустых елементов
            tempArr = tempArr.filter(val => val);
            // удаление одинаковых елементов
            tempArr = [...new Set(tempArr)];
            return tempArr.join(',');
        }
        return '';
    }

    delBlankLines(text): string {
        const stringArray = text.split('\n');
        const temp = [''];
        let x = 0;
        stringArray.map(val => {
            if (!!val) {
                temp[x] = val;
                x++;
            }
        });

        return temp.join('\n');
    }

    isNumeric(n): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    validationEmail(val: string): boolean {
        const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailReg.test(val);
    }

    formatMoney(val: string | number): string {
        return numeral(val).format('0,0.00');
    }

    formatDate(date: string, format: string): string | null {
        if (date) {
            return date = moment(date).format(format);
        }
        return null;
    }

    toggleActive(val, form, fields, cdr: any, isClearField = false): void {
        const required = val && isBoolean(val) || val.checked ? Validators.required : null;
        fields.map(field => {
            if (form.controls[field]) {
                form.controls[field].setValidators(required);
                if (!required && isClearField) {
                    form.controls[field].setValue('');
                }
                form.controls[field].markAsUntouched();
                form.controls[field].updateValueAndValidity();
            }
        });
        form.updateValueAndValidity();
        cdr.markForCheck();
    }

    validation(form: any, collapse = false, collapseName =  '', arrCollapse?: any[]): void {
        const controls = form.controls;
        Object.keys(controls).forEach(controlName => {
            if (controls[controlName].controls) {
                if (collapse && controls[controlName].invalid && arrCollapse) {
                    arrCollapse.push(controlName);
                }
                this.validation(controls[controlName], controlName === 'data', controlName, arrCollapse);
            }
            controls[controlName].markAsTouched();
        });
    }

    checkEmptyField(data: any): void {
        Object.keys(data).forEach(fieldName => {
            if (isObject(data[fieldName])) {
                this.checkEmptyField(data[fieldName]);
            } else if (!data[fieldName] && data[fieldName] !== 0 && data[fieldName] !== false) {
                delete data[fieldName];
            } else if (isBoolean(data[fieldName])) {
                data[fieldName] = data[fieldName] ? 1 : 0;
            }
        });
        return data;
    }

    capitalize(s): string {
        return s[0].toUpperCase() + s.slice(1);
    }

    validateDate(date: any): boolean {
        const aDate = moment(date, 'YYYY-MM-DD', true);
        return aDate.isValid();
    }

    isEmptyObject(obj): boolean {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false;
            }
        }
        return true;
    }
}
