import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';
import {COMMON} from '../../shared/contstants/common.const';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    private _changeProjectSection: BehaviorSubject<boolean | null>;

    /**
     * Constructor
     */
    constructor(private _cookieService: CookieService,
                private _titleService: Title) {

        this._changeProjectSection = new BehaviorSubject(false);
    }

    urlDependingValue(val?: any): string {
        let url = '';
        switch (true) {
            case val === 1: {
                url = this.urlWithProject();
                break;
            }
            default : {
                url = this.urlEmpty();
                break;
            }
        }
        return url;
    }


    urlEmpty(): string {
        return environment.apiUrl + '/api/backend';
    }

    urlWithProject(): string {
        return `${environment.apiUrl}/api/backend/projects/${this.get('projectId')}`;
    }

    clear(): void {
        this._cookieService.deleteAll();
    }

    check(field): any {
        return this._cookieService.check(COMMON.COOKIE_KEY + field);
    }

    set(field: string, data: any): void {
        this._cookieService.set(COMMON.COOKIE_KEY + field, JSON.stringify(data), null, '/');
    }

    get(field: string): any {
        try {
            return JSON.parse(this._cookieService.get(COMMON.COOKIE_KEY + field));
        } catch (e) {
            return undefined;
        }
    }

    // CONFIG SITE

    setConfig(data: any): void {
        sessionStorage['config'] = JSON.stringify(data);
        this.checkConfig();
    }

    checkConfig(): void {
        const config = this.config;
        if (config && config.data && config.data.favicon) {

            const favIcon = document.getElementById('appIcon') || document.createElement('link');
            favIcon['type'] = 'image/x-icon';
            favIcon['rel'] = 'shortcut icon';
            favIcon['href'] = 'https://quickstorage.net/dlucky/YpJElL58o9fPGY626WYOAZhXiCBYOrMKXLduf6XC.ico';
            // document.getElementsByTagName('head')[0].appendChild(this.favIcon);
            console.log(favIcon['href']);
        }
        if (config && config.name) {

            environment.currentSiteName = config.name;
            this._titleService.setTitle(config.name);
        }
    }

    resetConfig(): void {
        delete sessionStorage['config'];
    }

    get config(): any {
        if (sessionStorage['config']) {
            return JSON.parse(sessionStorage['config']);
        }
    }
}

