import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {document} from 'ngx-bootstrap/utils';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

    show: string | boolean = null;


    constructor(private location: Location,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.show = window.history.length && window.history.length > 1;
    }

    back(): void {
        this.route.queryParams.subscribe((queryParams) => {
            if (this.location.path().includes('/logs') && queryParams['customer_id']) {
                this.router.navigate(['/customers'], {queryParams: {project_id: queryParams.project_id}});
                return;
            }
            if (this.location.path().includes('/logs') && queryParams['server_id']) {
                this.router.navigate(['/servers'], {queryParams: {project_id: queryParams.project_id}});
                return;
            }
            if (this.location.path().includes('/servers')) {
                this.router.navigate(['/projects']);
                return;
            }
            if (this.location.path().includes('/customers')) {
                this.router.navigate(['/projects']);
                return;
            }
            this.location.back();
        }).unsubscribe();
    }
}
